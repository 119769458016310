<template>
  <div class="app-wrapper">
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <div class="map-container">
      <!-- Control button -->
      <div id="control" v-cloak>
        <v-btn icon @click="toggleControlPanel" class="control-btn">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </div>

      <!-- Control panel -->
      <div id="overlay" :class="{ in: showControlPanel }">
        <v-card elevation="0">
          <v-card-title class="itemSubHeading">Map Controls</v-card-title>
          <v-card-text>
            <v-layout wrap justify-center class="smallLightHeading">
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <!-- <v-flex xs12 lg6>
                    <v-checkbox
                      v-model="clusterMarkers"
                      label="Cluster Locations"
                      @change="updateMap"
                      class="small-checkboxmap"
                    ></v-checkbox>
                  </v-flex> -->
                  <v-flex xs12 lg6>
                    <v-checkbox
                      v-if="!clusterMarkers"
                      v-model="showLegend"
                      label="Add Legend"
                      @change="updateMap"
                      class="small-checkboxmap"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <span class="itemSubHeading">Marker colour based on</span>
              </v-flex>
              <v-flex xs12 class="smallLightHeading">
                <v-layout wrap justify-center>
                  <v-flex xs12>
                    <v-radio-group
                      v-model="markerColorBy"
                      @change="updateMarkerColors"
                    >
                      <v-radio label="Animal Name" value="common"></v-radio>
                      <v-radio label="Threat Level" value="schedule"></v-radio>
                      <!-- <v-radio label="Reason" value="reason"></v-radio> -->
                    </v-radio-group>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <span class="itemSubHeading">Add Layers to Map</span>
              </v-flex>
              <v-flex xs12 class="smallLightHeading" pa-0 pt-0>
                <v-layout wrap justify-start>
                  <v-flex xs12>
                    <v-checkbox
                      v-model="showStateLayer"
                      label="State"
                      @change="updateLayers"
                      dense
                      hide-details="false"
                      class="small-checkboxmap"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12>
                    <v-checkbox
                      v-model="showCirclesLayer"
                      dense
                      hide-details="false"
                      label="Circles"
                      class="small-checkboxmap"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12>
                    <v-checkbox
                      v-model="showDivisionsLayer"
                      dense
                      hide-details="false"
                      label="Divisions"
                      class="small-checkboxmap"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12>
                    <v-checkbox
                      v-model="showRangesLayer"
                      dense
                      hide-details="false"
                      label="Ranges"
                      class="small-checkboxmap"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pt-4>
              <v-flex xs12>
                <span class="itemSubHeading">Data Filter Section</span>
              </v-flex>
              <v-flex xs12 pt-3 class="smallLightHeading">
                <v-layout wrap justify-center>
                  <v-flex xs12>
                    <span>Animal Name</span>
                    <v-select
                      v-model="selectedCommonName"
                      dense
                      outlined
                      hide-details="auto"
                      :items="commonNames"
                      placeholder="Please Choose"
                      item-text="name"
                      item-value="_id"
                      @change="updateFilters"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 pt-1>
                    <span>Threat Level</span>
                    <v-select
                      dense
                      outlined
                      hide-details="auto"
                      v-model="selectedSchedule"
                      :items="schedules"
                      placeholder="Please Choose"
                      @change="updateFilters"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 pt-1>
                    <span>Situation</span>
                    <v-select
                      dense
                      outlined
                      hide-details="auto"
                      v-model="selectedReason"
                      :items="reasons"
                      placeholder="Please Choose"
                      @change="updateFilters"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 pt-1>
                    <span>Case Status</span>
                    <v-select
                      dense
                      outlined
                      hide-details="auto"
                      v-model="casestatus"
                      :items="casestatusList"
                      placeholder="Please Choose"
                      @change="updateFilters"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 pt-1>
                    <span>From Date</span>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          append-icon="mdi-calendar"
                          readonly
                          placeholder="Please Choose"
                          v-bind="attrs"
                          v-on="on"
                          dense
                          hide-details="auto"
                          outlined
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 pt-1>
                    <span>To Date</span>
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :return-value.sync="todate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="todate"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          placeholder="Please Choose"
                          dense
                          hide-details="auto"
                          outlined
                          :disabled="!date"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="todate"
                        no-title
                        scrollable
                        :min="date"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu2 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu2.save(todate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </div>

      <!-- Map component -->
      <l-map
        ref="map"
        class="full-map"
        :center="center"
        :zoom="zoom"
        :scroll-wheel-zoom="false"
        :zoomControl="false"
        :attributionControl="false"
        @ready="onMapReady"
      >
        <l-tile-layer :url="url"></l-tile-layer>
        <!-- State Boundary Layer -->
        <l-geo-json
          v-if="showStateLayer"
          :geojson="keralaGeoJSON"
          :options="geoJSONStyle"
        ></l-geo-json>
        <!-- Districts Boundary Layer -->
        <l-geo-json
          v-if="showCirclesLayer"
          :geojson="keralaDistrictsGeoJSON"
          :options="districtGeoJSONStyle"
        ></l-geo-json>
        <!-- Markers -->
        <l-marker
          v-for="(marker, index) in filteredMarkers"
          :key="index"
          :lat-lng="marker.latlng"
          :icon="getMarkerIcon(marker)"
        >
          <l-popup>{{ marker.popupContent }}</l-popup>
        </l-marker>
      </l-map>

      <!-- Legend -->
      <div v-if="showLegend && !clusterMarkers" class="legend">
        <h3>Legend</h3>
        <div v-for="(color, label) in legendItems" :key="label">
          <span :style="{ backgroundColor: color }" class="legend-color"></span>
          {{ label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup, LGeoJson } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";
export default {
  name: "MapComponent",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LGeoJson,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 7,
      center: [10.8505, 76.2711],
      markers: [],
      showControlPanel: false,
      clusterMarkers: false,
      casestatusList: [
        "Active Cases",
        "Assigned Cases",
        "Resolved Cases",
        "Released Cases",
      ],
      casestatus: "",
      showLegend: false,
      markerColorBy: "common",
      showStateLayer: false,
      showCirclesLayer: false,
      showDivisionsLayer: false,
      showRangesLayer: false,
      selectedCommonName: null,
      selectedSchedule: null,
      selectedReason: null,
      commonNames: [],
      schedules: ["High", "Medium", "Low"],
      reasons: ["Direct Sighting", "Indirect Sign"],
      // Kerala GeoJSON data
      keralaGeoJSON: {
        type: "Feature",
        properties: { name: "Kerala" },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [76.277832, 8.317298],
              [76.745605, 8.896932],
              [77.061768, 8.896932],
              [77.172852, 9.449062],
              [76.706543, 9.795678],
              [76.30127, 10.833306],
              [75.9375, 11.350797],
              [75.9375, 11.867351],
              [75.541992, 12.297068],
              [74.772949, 12.726084],
              [74.816895, 11.738302],
              [75.101318, 11.217755],
              [75.299072, 10.487812],
              [75.9375, 9.709057],
              [76.013184, 9.449062],
              [76.167297, 9.146034],
              [76.233215, 8.966637],
              [76.277832, 8.317298],
            ],
          ],
        },
      },
      // District GeoJSON data
      keralaDistrictsGeoJSON: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: { name: "Thiruvananthapuram" },
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [76.941, 8.508],
                  [77.159, 8.732],
                  [77.061, 8.896],
                  [76.745, 8.896],
                  [76.941, 8.508],
                ],
              ],
            },
          },
          {
            type: "Feature",
            properties: { name: "Kollam" },
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [76.541, 8.708],
                  [76.941, 8.508],
                  [77.061, 8.896],
                  [76.745, 9.186],
                  [76.541, 8.708],
                ],
              ],
            },
          },
          // Add more districts as needed
        ],
      },
      // Style for the GeoJSON layers
      geoJSONStyle: {
        style: {
          weight: 2,
          color: "#000",
          opacity: 1,
          fillOpacity: 0,
          dashArray: "5, 10",
        },
      },
      districtGeoJSONStyle: {
        style: {
          weight: 2,
          color: "#000",
          opacity: 1,
          fillOpacity: 0,
          dashArray: "5, 10",
        },
      },
      date: null,
      menu: false,
      menu2: false,
      todate: null,
      fullanimaldata: [],
    };
  },
  computed: {
    filteredMarkers() {
      return this.markers.filter((marker) => {
        if (
          this.selectedCommonName &&
          marker.commonName._id !== this.selectedCommonName
        )
          return false;
        if (this.selectedSchedule && marker.schedule !== this.selectedSchedule)
          return false;
        if (this.selectedReason && marker.reason !== this.selectedReason)
          return false;
        if (this.casestatus && marker.status !== this.casestatus) return false;
        return true;
      });
    },
    legendItems() {
      const items = {};
      if (this.markerColorBy === "common") {
        this.commonNames.forEach((item, index) => {
          // Use the name property from the object
          items[item.name] = this.getColor(index);
        });
      } else if (this.markerColorBy === "schedule") {
        this.schedules.forEach((schedule, index) => {
          items[schedule] = this.getColor(index);
        });
      } else if (this.markerColorBy === "reason") {
        this.reasons.forEach((reason, index) => {
          items[reason] = this.getColor(index);
        });
      }
      return items;
    },
  },
  beforeMount() {
    this.getanimalData();
    this.getfullanimalData();
  },
  mounted() {
    // Delete the default icon
    delete L.Icon.Default.prototype._getIconUrl;

    // Set a blank icon as the default
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  },
  methods: {
    toggleControlPanel() {
      this.showControlPanel = !this.showControlPanel;
    },
    updateMap() {
      console.log("Map updated");
    },
    getanimalData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/animal/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.commonNames = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getfullanimalData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/hwc/conflictreports/inmap",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.fullanimaldata = response.data.data;
              this.loadInitialMarkers();
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    updateFilters() {
      console.log("Filters updated");
    },
    updateMarkerColors() {
      console.log("Marker colors updated");
    },
    updateLayers() {
      if (this.$refs.map) {
        const map = this.$refs.map.mapObject;

        // Existing state layer logic
        if (this.showStateLayer) {
          map.fitBounds(L.geoJSON(this.keralaGeoJSON).getBounds(), {
            padding: [50, 50],
          });
        }

        // Add logic for circles (districts) layer
        if (this.showCirclesLayer) {
          map.fitBounds(L.geoJSON(this.keralaDistrictsGeoJSON).getBounds(), {
            padding: [50, 50],
          });
        }

        console.log("Layers updated");
      }
    },
    onMapReady() {
      const map = this.$refs.map.mapObject;
      map.zoomControl.remove();
      this.loadInitialMarkers();
    },
    loadInitialMarkers() {
      // Convert the fullanimaldata into markers format
      this.markers = this.fullanimaldata.map((item) => ({
        latlng: [item.location[1], item.location[0]], // Swap to [latitude, longitude]
        popupContent: this.createPopupContent(item),
        commonName: {
          _id: item.animalId ? item.animalId._id : "Unknown ID", // Check for null
          name: item.animalId ? item.animalId.name : "Unknown Animal", // Check for null
        },
        schedule: item.threatlevel, // Using threatlevel as schedule
        reason: item.scenarioId ? item.scenarioId.name : "Unknown", // Check for null
        status: item.status,
      }));
    },
    createPopupContent(item) {
      // Check if item.animalId is not null or undefined
      const animalName = item.animalId ? item.animalId.name : "Unknown Animal";

      // Check if item.scenarioId is not null or undefined
      const scenarioName = item.scenarioId
        ? item.scenarioId.name
        : "Unknown Scenario";

      return `
    <div>
      <h3>${animalName}</h3>
      <p>Scenario: ${scenarioName}</p>
      <p>Status: ${item.status}</p>
    </div>
  `;
    },
    getMarkerIcon(marker) {
      const iconColor = this.getMarkerColor(marker);
      return L.divIcon({
        className: "custom-marker-icon",
        html: `<div style="background-color: ${iconColor}; width: 20px; height: 20px; border-radius: 50%; border: 2px solid white; box-shadow: 0 0 5px rgba(0,0,0,0.5);"></div>`,
        iconSize: [20, 20],
        iconAnchor: [10, 10],
      });
    },
    getMarkerColor(marker) {
      if (this.markerColorBy === "common") {
        const index = this.commonNames.findIndex(
          (item) => item._id === marker.commonName._id
        );
        return this.getColor(index);
      } else if (this.markerColorBy === "schedule") {
        // Map threat levels to indices
        const threatLevels = ["High", "Medium", "Low"];
        const index = threatLevels.indexOf(marker.schedule);
        return this.getColor(index);
      } else if (this.markerColorBy === "reason") {
        const index = this.reasons.indexOf(marker.reason);
        return this.getColor(index);
      }
      return "#000000";
    },
    getColor(index) {
      const colors = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"];
      return colors[index % colors.length];
    },
  },
};
</script>

  <style>
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevents scrolling */
}

.app-wrapper {
  width: 99vw; /* Use viewport width */
  height: 100vh; /* Use viewport height */
  position: relative;
  margin: 0; /* Remove any margin */
}

.map-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%; /* Ensure full width */
  height: 100%; /* Ensure full height */
  overflow: hidden; /* Prevent overflow */
}

.custom-marker-icon {
  background: none !important; /* Remove any background */
  border: none !important; /* Remove any border */
  box-shadow: none !important; /* Remove any shadow */
  width: 20px; /* Set width */
  height: 20px; /* Set height */
  border-radius: 50%; /* Ensure it is circular */
}

.leaflet-marker-icon {
  background: none !important;
  border: none !important;
}

.full-map {
  width: 100%;
  height: 100%;
}

#overlay {
  position: absolute;
  z-index: 1000;
  width: 300px;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  left: -310px; /* Keep this for control panel toggle */
  top: 60px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  transition: left 0 0.3s ease-in-out;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}

#overlay.in {
  left: 10px;
}

.control-btn {
  position: absolute;
  z-index: 1001;
  top: 10px;
  left: 10px;
}

.legend {
  position: absolute;
  z-index: 1000;
  right: 10px;
  bottom: 20px;
  max-height: 300px;
  width: fit-content;
  min-width: 150px;
  background: rgba(255, 255, 255, 0.8);
  overflow-y: auto;
  padding: 10px;
  border-radius: 5px;
}

.legend-color {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
}
</style>